@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@300;600&display=swap');

body {
  font-family: 'Recursive', sans-serif;
  color: white;
}

.video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -99;
}

.video-background {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

details > summary {
  list-style-type: none;
  cursor: pointer;
}

details > summary::-webkit-details-marker {
  display: none;
}